import { useEffect, useState } from "react";
import { useParams } from "react-router";

import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";
import LoadingBlankPage from "../../components/loading-blank-page/LoadingBlankPage";

import CouponContent from "./coupon-content/CouponContent";

import { DoGetCouponContent } from "../../services/api/api.blog";

import "./get-coupon-page.scss";
import { Helmet } from "react-helmet-async";

function GetCardPage() {
    const { drug: drugSlugName } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [couponContent, setCouponContent] = useState({});

    useEffect(() => {
        let didCancel = false;
        async function doRequest() {
            if (drugSlugName) {
                setLoading(true);
                const result = await DoGetCouponContent(drugSlugName);
                if (!didCancel) {
                    if (result) {
                        setCouponContent(result);
                    }
                    setLoading(false);
                }
            }
        }

        doRequest();

        return () => {
            didCancel = true;
            setCouponContent({});
        };
    }, [drugSlugName]);

    return (
        <div className="page-coupon">
            {couponContent?.meta && (
                <Helmet>
                    <title>{couponContent.meta?.meta_title}</title>
                    <meta
                        name="description"
                        content={couponContent.meta?.meta_description}
                    />
                </Helmet>
            )}
            <div className="container">
                <div className="page-coupon__content">
                    {isLoading ? (
                        <LoadingBlankPage />
                    ) : (
                        <CouponContent content={couponContent} />
                    )}
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}

export default GetCardPage;
