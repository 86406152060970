import SearchHighlight from "./images/highlights/search.svg";
import PriceHighlight from "./images/highlights/price.svg";
import MedicationsHighlight from "./images/highlights/medications.svg";

import "./application-page.scss";
// import AppDownloadBtns from "../../components/app-download/AppDownloadBtns";
// import TextMeTheApp from "../../components/app-download/TextMeTheApp";

// const ReviewsItems = () => {
//     const items = [1, 2, 3];
//     return (
//         <div className="app-reviews__content">
//             {items.map((item, key) => (
//                 <div key={key} className="app-highlight__item">
//                     <div className="app-highlight__item--wrap">
//                         <div className="app-highlight__item--header">
//                             <span className="date">12.05.2022</span>
//                             <span className="reviewer-name">Jenifer</span>
//                             <span className="rate">
//                                 <span className="star" />
//                                 <span className="star" />
//                                 <span className="star" />
//                                 <span className="star" />
//                                 <span className="star" />
//                             </span>
//                         </div>
//                         <div className="app-highlight__item--content">
//                             <p>
//                                 Lorem ipsum dolor sit amet, consectetur
//                                 adipiscing elit. Nisl, volutpat suspendisse
//                                 consectetur congue suscipit pretium nec
//                                 pellentesque mauris.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// };

function ApplicationPage() {
    return (
        <div className="page-application">
            <div className="container-fluid">
                <div className="row">
                    <div className="page-application__hero">
                        <div className="application-hero__title">
                            <h1>
                                Get exclusive discounts up to 95% on your
                                medications with the CharityRx app
                            </h1>
                            <p className="subtitle">
                                Accepted in 60,000+ pharmacies <br /> No
                                insurance required
                            </p>
                        </div>
                    </div>
                    <div className="page-application__downloadbtn">
                        {/* <AppDownloadBtns /> */}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="page-application__highlights">
                        <div className="app-highlight__item">
                            <img src={SearchHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Compare prices</h3>
                                <p>
                                    Search and compare prices with exclusive
                                    CharityRx discounts on FDA approved
                                    medications in over 68000 U.S. pharmacies.
                                </p>
                            </div>
                        </div>
                        <div className="app-highlight__item">
                            <img src={MedicationsHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Free Coupons</h3>
                                <p>
                                    Show your CharityRX coupon to the any
                                    pharmacist and save up to 80% on your
                                    prescriptions.
                                </p>
                            </div>
                        </div>
                        <div className="app-highlight__item">
                            <img src={PriceHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Big Savings</h3>
                                <p>
                                    Download free drug coupons instantly on any
                                    device and begin saving immediately
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="page-application__reviews">
                        <div className="app-reviews__title">
                            <h2>App reviews</h2>
                            <p className="subtitle">
                                Here’s what people have been saying about their
                                CharityRx experience.
                            </p>
                        </div>
                        <ReviewsItems />
                    </div> */}
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="page-application__download-app-now">
                        <h2>The CharityRx app is coming soon!</h2>
                        {/* <h2>Download the CharityRx app soon!</h2> */}
                        {/* <AppDownloadBtns /> */}
                        {/* <div className="download-app__form">
                            <span className="or">Or</span>
                            <div className="download-app__form--content">
                                <p className="notice">
                                    Let us text you a link to download our free
                                    Android or iPhone app!
                                </p>
                                <TextMeTheApp />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPage;
