import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import PharmacyPartnersList from "../../../components/pharmacy-partners-list/PharmacyPartnersList";
import "./section-partners.scss";
function SectionPartners({ withTitle, withLink }) {
    return (
        <div className="section__partners">
            {withTitle && <h2>Leading partners</h2>}
            <div className="section__partners--wrap">
                <PharmacyPartnersList />
            </div>
            {withLink && (
                <div className="section__partners--link">
                    <NavLink to="/pharmacies">
                        <button className="button purple">
                            All pharmacies
                        </button>
                    </NavLink>
                </div>
            )}
        </div>
    );
}

SectionPartners.propTypes = {
    withTitle: PropTypes.bool,
    withLink: PropTypes.bool,
};

export default SectionPartners;
