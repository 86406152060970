import client from "./client";

export const DoGetBlogCategories = async () => {
    try {
        const response = await client.post("/", {
            action: `blog/categories`,
            method: "get",
            data: {},
        });
        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetBlogPosts = async () => {
    try {
        const response = await client.post("/", {
            action: `blog/list`,
            method: "get",
            data: {},
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetSingleBlogPost = async (slug) => {
    try {
        const response = await client.post("/", {
            action: `blog/single/${slug}`,
            method: "get",
            data: {},
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetCouponContent = async (slug) => {
    try {
        const response = await client.post("/", {
            action: `content/coupon-description/${slug}`,
            method: "get",
            data: {},
        });
        return response.data;
    } catch (error) {
        return null;
    }
};
