import Spinner from "../spinner/Spinner";

import "./loading-blank-page.scss";

function LoadingBlankPage() {
    return (
        <div className="component__loading-blank-page">
            <Spinner />
        </div>
    );
}

LoadingBlankPage.propTypes = {};

LoadingBlankPage.defaultProps = {};

export default LoadingBlankPage;
