import PropTypes from "prop-types";

import RxCard from "../../../components/rx-card/RxCard";
import RxCardActions from "../../../components/rx-card-actions/RxCardActions";
import ListBenefitsItems from "../../../components/list-benefits-items/ListBenefitsItems";
import DownloadCardAction from "../../../components/rx-card-actions/DownloadCardAction";
import FaqQuestionItem from "../../../components/faq-question-item/FaqQuestionItem";

import "../get-coupon-page.scss";
import { isEmpty } from "lodash";
import CouponEmptyContent from "./CouponEmptyContent";

const ContentItem = ({ className, title, content }) => {
    return (
        <div className={className}>
            <h2>{title}</h2>
            <p
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </div>
    );
};

ContentItem.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

function CouponContent({ content }) {
    if (isEmpty(content)) return <CouponEmptyContent />;

    const {
        title,
        subtitle,
        description,
        downloadBtnTitle,
        benefits,
        savings,
        questions,
    } = content;

    return (
        <>
            <div className="row">
                <div className="page-coupon__content--title">
                    <h1 className="page-title">{title}</h1>
                    <p className="subtitle">{subtitle}</p>
                </div>
            </div>
            <div className="row">
                <div className="page-coupon__content--rxcard">
                    <RxCard />
                    <RxCardActions />
                </div>
            </div>
            <div className="row">
                {description && (
                    <div className="page-coupon__content--drug-desc thin-custom-row">
                        <div className="drug-description__content">
                            {description.map((item, i) => (
                                <ContentItem
                                    key={i}
                                    className="drug-description__content--item"
                                    title={item.title}
                                    content={item.content}
                                />
                            ))}
                        </div>
                        <div className="drug-description__image">
                            <img
                                src={require("../images/coupon-image-2x.png")}
                            />
                        </div>
                    </div>
                )}

                <div className="page-coupon__content--download">
                    <DownloadCardAction title={downloadBtnTitle} />
                </div>

                {benefits && (
                    <div className="page-coupon__content--benefits">
                        <h2>Why Choose the CharityRx {title} Card</h2>
                        <div className="content__benefits">
                            <ListBenefitsItems items={benefits} />
                        </div>
                    </div>
                )}

                <div className="page-coupon__content--download">
                    <DownloadCardAction title={downloadBtnTitle} />
                </div>

                {savings && (
                    <div className="page-coupon__content--savings thin-custom-row">
                        <div className="content__savings">
                            {savings.map((item, i) => (
                                <ContentItem
                                    key={i}
                                    className="content__savings--item"
                                    title={item.title}
                                    content={item.content}
                                />
                            ))}
                        </div>
                    </div>
                )}

                <div className="page-coupon__content--download">
                    <DownloadCardAction title={downloadBtnTitle} />
                </div>

                {questions && (
                    <div className="page-coupon__content--faq thin-custom-row">
                        <h2>Frequently Asked Questions</h2>
                        <div className="content__faq">
                            {questions.map((item, i) => (
                                <FaqQuestionItem key={i} item={item} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

CouponContent.propTypes = {
    content: PropTypes.object,
};

CouponContent.defaultProps = {
    content: {},
};

export default CouponContent;
