import PropTypes from "prop-types";

function DownloadCardAction({ title }) {
    return (
        <div className="download-card-action">
            <a
                href={require("../rx-card/images/Charity_Rx_card.pdf")}
                download={"Charity_Rx_card.pdf"}
                style={{ width: "100%" }}
                rel="noreferrer"
                target="_blank"
            >
                <button className="button purple download-btn">{title}</button>
            </a>
        </div>
    );
}

DownloadCardAction.propTypes = {
    title: PropTypes.string,
};

DownloadCardAction.defaultProps = {
    title: "Download",
};

export default DownloadCardAction;
